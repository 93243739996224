import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { EmbeddedCheckoutProvider, EmbeddedCheckout, } from "@stripe/react-stripe-js";

import fetch from "../axios/manager";
import AlertDialogFailure from "../dialogs/alertdialog/alertdialog_failure";
import * as Loader from "../dialogs/loader/loaderop";
import showToast from "../dialogs/loader/toast";
import { STRIPE_PUBLISHABLE_URL } from '../global/vars/env';
import { show } from "../redux/slices/alertdialog";

const StripeCheckoutScreen = () => {
    const { uuid, subscriptionUUID } = useParams();
    const dispatcher = useDispatch();
    const [stripe, setStripe] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const upgrade = queryParams.get("upgrade");

    const buyOperations = async () => {
        const [data, stripeError,] = await fetch({
            route: "payments/checkout-session",
            requestType: "post",
            body: {
                uuid: uuid,
                subscriptionUUID: subscriptionUUID,
            },
        });

        if (stripeError != null) {
            Loader.hide();
            showToast(stripeError);
            return;
        }

        Loader.hide();


        if (stripeError) {
            dispatcher(show({
                child: <AlertDialogFailure title="Error"
                    description={`Operation failed with code - ${stripeError}`}
                    onClick={() => {
                        if (window.processFailure) {
                            window.processFailure();
                        }
                    }} />
            }));
            return;
        }

        setClientSecret(data.clientSecret);
    };

    const upgradeOperations = async () => {
        const [data, stripeError,] = await fetch({
            route: `payments/subscription/${subscriptionUUID}/upgrade`,
            requestType: "put",
            body: {
                uuid: uuid,
            },
        });

        if (stripeError != null) {
            Loader.hide();
            showToast(stripeError);
            return;
        }

        Loader.hide();


        if (stripeError) {
            dispatcher(show({
                child: <AlertDialogFailure title="Error"
                    description={`Operation failed with code - ${stripeError}`}
                    onClick={() => {
                        if (window.processFailure) {
                            window.processFailure();
                        }
                    }} />
            }));
            return;
        }

        setClientSecret(data.clientSecret);
    };

    const init = async () => {
        Loader.show();

        const obj = await loadStripe(STRIPE_PUBLISHABLE_URL);

        setStripe(obj);
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (stripe == null) return;

        if (upgrade === "1") {
            upgradeOperations();
        }
        else {
            buyOperations();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripe]);

    return <div className="h-[100dvh] flex flex-col">
        <div className="flex flex-col w-[100%] my-auto">
            {clientSecret != null &&
                <EmbeddedCheckoutProvider stripe={stripe} options={{ clientSecret: clientSecret }}>
                    <EmbeddedCheckout className='w-full' />
                </EmbeddedCheckoutProvider>}
        </div>
    </div>;
};

export default StripeCheckoutScreen;