import * as adapter from "./adapter";

const fetch = async ({ route, requestType, body, params }) => {
    try {
        const response = await adapter.fetch({
            route: route, requestType: requestType, body: body, params: params,
        });

        if (response.data.error != null) {
            return [null, response.data.res, response.status];
        }

        return [response.data, null, response.status];
    }
    catch (e) {
        let error = e.response?.data?.res ?? e.message ?? "some error occured";

        if (error.includes(":")) {
            error = error.split(":")[1];
        }
        if (e.isAxiosError) {
            return [null, error, e.response?.status];
        }

        return [null, e];
    }
};

export default fetch;