import axios from "axios";

// const domain = "http://localhost:3000";
const domain = "https://api.onceusa.com";

axios.defaults.baseURL = domain;

const fetch = async ({ route, requestType, body, params }) => {
    const config = {
        params: params,
    };

    switch (requestType) {
        case "post":
            return axios.post(route, body, config);
        case "put":
            return axios.put(route, body, config);
        case "get":
            return axios.get(route, config);
        case "delete":
            return axios.delete(route, config);
        default: return null;
    }
};

export { fetch, domain };