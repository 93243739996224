import React from "react";
import { useSelector } from "react-redux";
import LoadingBar from "react-top-loading-bar";

const LoadingBarComponent = () => {
    const topLoadingBarSlice = useSelector(state => state.loadingBarSlice);

    return <LoadingBar color="#099F44" height={4} progress={topLoadingBarSlice.progress} />;
};

export default LoadingBarComponent;