import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { STRIPE_PUBLISHABLE_URL } from "../global/vars/env";
import * as Loader from "../dialogs/loader/loaderop";
import { useParams } from "react-router-dom";
import { show } from "../redux/slices/alertdialog";
import AlertDialogFailure from "../dialogs/alertdialog/alertdialog_failure";
import AlertDialogSucess from "../dialogs/alertdialog/alertdialog_success";
import fetch from "../axios/manager";

export default function CheckoutResult() {
    const dispatcher = useDispatch();
    const [description, setDescription] = useState(null);
    const [stripe, setStripe] = useState(null);
    const { checkoutSessionId } = useParams();

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        operations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripe]);

    const operations = async () => {
        const [data, stripeError,] = await fetch({
            route: `payments/checkout-status/${checkoutSessionId}`,
            requestType: "get",
        });

        Loader.hide();

        if (stripeError != null) {
            dispatcher(
                show({
                    child: <AlertDialogFailure title="Error"
                        description={`Operation failed with error - ${stripeError}`}
                        onClick={() => {
                            if (window.processFailure) {
                                window.processFailure();
                            }
                        }}
                    />
                }));

            setDescription(`Error - ${stripeError}`);
            return;
        }

        const status = data.status;

        if (status === "complete") {
            dispatcher(
                show({
                    child: <AlertDialogSucess title="Success"
                        description={`Payment is successful, Invoice is sent to your registered email address`}
                        onClick={() => {
                            if (window.processSucess) {
                                window.processSucess();
                            }
                        }}
                    />
                }));

            setDescription("Payment successful you can go back now.");
            return;
        }
        else if (status === "open") {
            setDescription("Payment is underway");
        }
        else if (status === "expired") {
            setDescription("Session is expired please try again.");
        }
    };

    const init = async () => {
        Loader.show();

        const obj = await loadStripe(STRIPE_PUBLISHABLE_URL);

        setStripe(obj);
    };

    return <div className="h-[100dvh] flex flex-col">
        <div className="flex flex-col w-[100%] justify-center h-[80vh] items-center">
            {description != null && <h3 className="text-sm font-medium">{description}</h3>}
        </div>
    </div>;
}