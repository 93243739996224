import { tailChase } from 'ldrs';
import React from 'react';

const Loader = () => {
    tailChase.register();

    return <div style={{ display: "none" }} className=" fixed h-[100vh] w-[100vw] overflow-hidden transition-all duration-300 pointer-events-none z-[11] flex items-center justify-center" id="loader-parent">
        <div id="loader">
            <l-tail-chase
                size="60"
                speed="1.75"
                color="#099F44"
            ></l-tail-chase>
        </div>
    </div>;
};

export default Loader;