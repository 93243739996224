import React from "react";
import { useDispatch } from "react-redux";

import { ReactComponent as Cross } from "../../icons/cross.svg";
import Button from "../../global/components/buttton";
import { hide } from "../../redux/slices/alertdialog";

const AlertDialogFailure = ({ title, description, onClick }) => {
    const dispatch = useDispatch();

    return <div className="flex flex-col justify-between items-center sm:py-10 sm:px-14 p-5 max-w-[500px] w-full">
        <div className="border-red p-1 border-2 sm:h-[100px] sm:w-[100px] w-[70px] h-[70px] rounded-[50%]">
            <div className="bg-red rounded-[50%] h-[100%] w-[100%] flex items-center justify-center">
                <Cross style={{ filter: "invert(93%) sepia(100%) saturate(0%) hue-rotate(265deg) brightness(105%) contrast(108%)" }}
                    height="50%" width="50%" />
            </div>
        </div>

        <h1 className="text-primary-dark font-semibold sm:text-xl text-lg my-5">{title}</h1>
        <h1 className="text-md text-center mb-9">{description}</h1>

        <Button child="OK" extraCss="w-[100%] h-11" onClick={() => {
            onClick();
            dispatch(hide());
        }} />
    </div>;
};

export default AlertDialogFailure;